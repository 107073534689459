import React from "react";
import {
  TextField,
  Box,
  Button,
  Stack,
  Typography,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  Chip,
  MenuItem,
  Checkbox,
  Theme,
  useTheme,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import FirmListing from "../FirmListing/FirmListing";
import { useNavigate } from "react-router-dom";
import ThriveModal from "../../common/ThriveModal";
import EditFirm from "../FirmListing/EditFirm";
import ThriveUserSelection from "../../common/ThriveUserSelection";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import zipcodes from "../../../assets/zipcode";
import cities from "../../../assets/city";

type Search = {
  title: string;
  bussType: string[];
  subBussType: string[];
  emergOrEst: string;
  grantStatus: string;
  zipcode: string[];
  city: string[];
  certification: string[];
  classification: string[];
  businessChannels: string[];
};

const searchInit: Search = {
  title: "",
  bussType: [],
  subBussType: [],
  emergOrEst: "",
  grantStatus: "",
  zipcode: [],
  city: [],
  certification: [],
  classification: [],
  businessChannels: [],
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(
  name: string,
  assistance_requested: readonly string[],
  theme: Theme
) {
  return {
    fontWeight:
      assistance_requested.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const primaryBussTypes = [
  "Accommodations - Bed and breakfast, or any other lodging facility",
  "Business Services",
  "Construction and Maintenance Services - Grading, excavating, other construction and maintenance services",
  "Engineering, Design and Development",
  "Entertainment and Recreation",
  "Food",
  "Healthcare - chiropractors, dental, health clinics",
  "Maintenance & Repair - automobile repair, automobile dealership, cellular phone repair, landscaping and lawn maintenance",
  "Nonprofit - churches, religious centers, advocacy organizations, etc.",
  "Other",
  "Manufacturing",
  "Personal Services",
  "Professional & Other Services - taxes, consulting, publishing, marketing",
  "Retail",
  "Technology",
  "Transportation - limousine services, etc.",
];

const classifications = [
  "Woman-Owned",
  "Minority-Owned",
  "Veteran-Owned",
  "Disabled-Owned",
  "LGBTQ+ Owned",
  "Youth Owned",
  "Low to Moderate Income Owned",
  "Non-English Business",
  "NEP",
];

const certifications = [
  "Certified Women Owned",
  "Certified Minority Owned",
  "Certified Disabled Owned",
  "Certified Veteran Owned",
  "Certified LGBTQ+ Owned",
];

const businessChannels = [
  "Home-based",
  "Selling Online",
  "In-Person Sales",
  "Commercial Location",
  "Mobile Business",
];

const ExploreFirm = () => {
  const theme = useTheme();
  const token: string | undefined = Cookies.get("token");
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`, // Add JWT token to Authorization header
    },
  };
  const [apiCalled, setApiCalled] = React.useState<boolean>(false);
  const [showFilter, setShowFilter] = React.useState<boolean>(true);
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const [search, setSearch] = React.useState(searchInit);
  const [firmData, setFirmData] = React.useState<any>({ firms: [] });
  const [selectedRow, setSelectedRow] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState<any>({
    label: user.firstName + " " + user.lastName,
    id: user._id,
  });
  const [newFirmOpen, setNewFirmOpen] = React.useState(false);
  const [userSelectOpen, setUserSelectOpen] = React.useState(false);
  const [dataFlag, setDataFlag] = React.useState<boolean>(false);
  const [editedFirm, setEditedFirm] = React.useState({
    State: "MI",
    County: "Oakland",
    Client: "Yes",
  });
  const [editedGrant, setEditedGrant] = React.useState({});
  const navigate = useNavigate();

  const [showOnlyAvailableFirms, setShowOnlyAvailableFirms] =
    React.useState<boolean>(false);
  React.useEffect(() => {}, [firmData]);

  const exportFirms = (firms: any) => {
    let csvContent: any[] = [];
    let csvHeading = [
      "thriveID",
      "Company Name",
      "Contact",
      "Email",
      "Phone",
      "Website",
      "Address",
      "City",
      "ZIP",
      "Founding Year",
      "Company Narrative",
      "primaryIndustry",
      "secondaryIndustry",
      "Annual Revenue",
      "Employees",
      "Woman-Owned",
      "Veteran-Owned",
      "Minority-Owned",
      "firmScore",
      "totalGrantsValue",
      "Home-based",
      "Selling Online",
      "In-Person Sales",
      "Commercial Location",
      "Mobile Business",
    ];
    csvContent.push(csvHeading);
    for (const firm of firms) {
      let csvRow = [];
      for (const item of csvHeading) {
        if (firm[item]) {
          csvRow.push(firm[item]);
        } else {
          csvRow.push("");
        }
      }
      csvContent.push(csvRow);
    }

    let csvString = ``;
    for (const row of csvContent) {
      let rowString = `\n`;
      for (const item of row) {
        rowString = rowString + `"${item}",`;
      }
      csvString = csvString + rowString;
    }

    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    // Create a hidden anchor element
    const a = document.createElement("a");
    a.href = url;
    a.download = "firms_export.csv";
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const handleSubBussClick = (e: any) => {
    let selectedSubBussType: any;
    if (e.target.getAttribute("data-value")) {
      selectedSubBussType = e.target.getAttribute("data-value");
    }
    if (e.target.value) {
      selectedSubBussType = e.target.value;
    }
    let tempBussType = [...search.subBussType];
    if (tempBussType.indexOf(selectedSubBussType) === -1) {
      tempBussType.push(selectedSubBussType);
    } else {
      tempBussType = tempBussType.filter((x) => x !== selectedSubBussType);
    }
    setSearch({ ...search, subBussType: tempBussType });
  };

  const showSecondaryCategory = (bussType: any) => {
    let shownSecondaryCategoty = [];
    if (bussType.includes("Business Services")) {
      shownSecondaryCategoty.push(
        "Business Services - Landscaping, extermination, janitorial and housekeeping services"
      );
      shownSecondaryCategoty.push(
        "Business Services - Printing, market research, photography, advertising, public relations and related services"
      );
      shownSecondaryCategoty.push(
        "Business Services - Computer systems design, software, programming services"
      );
      shownSecondaryCategoty.push(
        "Business Services - Interior, industrial, graphic, and other specialized design services"
      );
    }
    if (bussType.includes("Entertainment and Recreation")) {
      shownSecondaryCategoty.push(
        "Entertainment and Recreation - Golf courses, skiing facilities, other recreation industries"
      );
      shownSecondaryCategoty.push(
        "Entertainment and Recreation - Yoga studios, gyms, fitness studios, personal trainers"
      );
    }
    if (bussType.includes("Personal Services")) {
      shownSecondaryCategoty.push("Personal Services - Childcare");
      shownSecondaryCategoty.push(
        "Personal Services - Barber shops, hair and nail salons"
      );
      shownSecondaryCategoty.push(
        "Personal Services - Diet, weight loss, spas, massage, other personal care services"
      );
    }
    if (bussType.includes("Retail")) {
      shownSecondaryCategoty.push(
        "Retail - Bookstores, hardware, anything sold that is not food"
      );
      shownSecondaryCategoty.push(
        "Retail - Clothing, shoe, sporting goods, resale and consignment stores"
      );
      shownSecondaryCategoty.push(
        "Retail - Jewelry, art, hobby, musical instrument, pet supply and other miscellaneous retail stores"
      );
      shownSecondaryCategoty.push(
        "Retail - Pharmacies, cosmetics, optical goods, other health and personal care stores"
      );
      shownSecondaryCategoty.push("Retail - Groceries");
    }

    return (
      <>
        {shownSecondaryCategoty.length > 0 ? (
          <FormControl style={{ width: "100%", marginTop: "20px" }}>
            <InputLabel>Secondary Business Type</InputLabel>
            <Select
              label={"Secondary Business Type"}
              multiple
              value={search.subBussType}
              input={<OutlinedInput label="Secondary Business Type" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {shownSecondaryCategoty.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, search.subBussType, theme)}
                  onClick={handleSubBussClick}
                >
                  <Checkbox
                    value={name}
                    checked={search.subBussType.indexOf(name) > -1}
                    // onChange={handleBussChange}
                  />
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <></>
        )}
      </>
    );
  };

  const handleBussClick = (e: any) => {
    let selectedBussType: any;
    if (e.target.getAttribute("data-value")) {
      selectedBussType = e.target.getAttribute("data-value");
    }
    if (e.target.value) {
      selectedBussType = e.target.value;
    }
    let tempBussType = [...search.bussType];
    let tempSubBussType = [...search.subBussType];

    if (tempBussType.indexOf(selectedBussType) === -1) {
      tempBussType.push(selectedBussType);
    } else {
      tempBussType = tempBussType.filter((x) => x !== selectedBussType);
      tempSubBussType = tempBussType.filter(
        (x) => !x.includes(selectedBussType)
      );
    }

    setSearch({
      ...search,
      bussType: tempBussType,
      subBussType: tempSubBussType,
    });
  };

  const handleCCBClick = (e: any, type: keyof typeof search) => {
    let selectedCCBC: any;
    if (e.target.getAttribute("data-value")) {
      selectedCCBC = e.target.getAttribute("data-value");
    }
    if (e.target.value) {
      selectedCCBC = e.target.value;
    }

    let tempCCBC: string[] = [];
    if (Array.isArray(search[type])) {
      tempCCBC = [...(search[type] as string[])];
    }

    if (tempCCBC.indexOf(selectedCCBC) === -1) {
      tempCCBC.push(selectedCCBC);
    } else {
      tempCCBC = tempCCBC.filter((x) => x !== selectedCCBC);
    }

    setSearch({
      ...search,
      [type]: tempCCBC,
    });
  };

  const handleSubmit = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/firms/firm_listing`,
        search,
        axiosConfig
      )
      .then((res) => {
        // console.log(res.data);
        setFirmData(res.data);
        if (res.data["firms"].length === 0) {
          setShowFilter(true);
        } else {
          setShowFilter(false);
        }
        setApiCalled(true);
      });
  };

  const handleUserSelect = () => {
    if (user.role === "admin") {
      setUserSelectOpen(true);
    } else {
      handleVisitPlan();
    }
  };

  const handleVisitPlan = () => {
    let displayFirmList: any[];
    if (showOnlyAvailableFirms) {
      displayFirmList = firmData["firms"].filter((item: any, index: number) => {
        return !item["openCase"];
      });
    } else {
      displayFirmList = firmData["firms"];
    }
    const data = selectedRow.map((row: number) => {
      return { _id: displayFirmList[row]["_id"] };
    });

    axios
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/cases`,
        { firms: data, bfc: selectedUser.id },
        axiosConfig
      )
      .then((res) => {
        // console.log(res.data);
      });
    navigate("/firmReport", {
      state: { firmData: { firms: displayFirmList }, indices: selectedRow },
    });
  };

  const handleAddNewFirm = () => {
    setNewFirmOpen(true);
  };

  let selectedRoww: any = [...selectedRow];
  const handleFirmSelect = (data: any) => {
    selectedRoww = data;
    setSelectedRow(selectedRoww);
  };

  let displayFirmList: any;
  if (showOnlyAvailableFirms) {
    displayFirmList = firmData["firms"].filter((item: any, index: number) => {
      return !item["openCase"];
    });
  } else {
    displayFirmList = firmData["firms"];
  }

  return (
    <Box
      sx={{
        width: "100%",
        height: 700,
        backgroundColor: "white",
        borderRadius: "15px",
      }}
    >
      {/* <a href="/dashboard/exploreFirmBeta">
        <img
          src={`${process.env.REACT_APP_API_DOMAIN}/images/beta.webp`}
          width={60}
          height={60}
          style={{ float: "right", transform: "rotate(45deg)" }}
        ></img>
      </a> */}
      <Box sx={{ textAlign: "right" }}>
        <Box>
          <AddCircleOutlineIcon
            onClick={handleAddNewFirm}
            fontSize="large"
            sx={{ color: "#9e1f63", cursor: "pointer" }}
          ></AddCircleOutlineIcon>
        </Box>
      </Box>

      {showFilter && (
        <Stack
          spacing={2}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            width: "700px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid #9e1f63",
              margin: "10px 0",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                backgroundColor: "white",
                padding: "0 10px",
                color: "#9e1f63",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              COMPANY
            </Typography>
          </Box>
          <TextField
            sx={{ width: "100%" }}
            label="Company title, contact, email, phone, website search"
            variant="outlined"
            onChange={(e) => {
              setSearch({ ...search, title: e.target.value });
            }}
            // onKeyDown={(e) => {
            //   if (e.key === "Enter" && search.trim() !== "") {
            //     handleSubmit();
            //   }
            // }}
          />
          <FormControl style={{ width: "100%" }}>
            <InputLabel>Primary Business Type</InputLabel>
            <Select
              label={"Primary Business Type"}
              multiple
              value={search.bussType}
              // onChange={handleBussChange}

              input={<OutlinedInput label="Primary Business Type" />}
              renderValue={(selected) => (
                <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                  {selected.map((value) => (
                    <Chip key={value} label={value} />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {primaryBussTypes.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, search.bussType, theme)}
                  onClick={handleBussClick}
                >
                  <Checkbox
                    value={name}
                    checked={search.bussType.indexOf(name) > -1}
                    // onChange={handleBussChange}
                  />
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {showSecondaryCategory(search["bussType"])}
          <Stack
            sx={{ width: "100%" }}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent={"space-between"}
          >
            <FormControl sx={{ width: { xs: "100%", sm: "48%" } }}>
              <InputLabel>Business Stage</InputLabel>
              <Select
                sx={{ textAlign: "left" }}
                label={"Business Stage"}
                value={search.emergOrEst}
                onChange={(e) => {
                  setSearch({ ...search, emergOrEst: e.target.value });
                }}
                input={<OutlinedInput label="Business Stage" />}
                MenuProps={MenuProps}
              >
                <MenuItem value={"Emerging"}>Emerging</MenuItem>
                <MenuItem value={"Established"}>Established</MenuItem>
                <MenuItem value={"No Preference"}>No Preference</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ width: { xs: "100%", sm: "48%" } }}>
              <InputLabel>Grant Status</InputLabel>
              <Select
                sx={{ textAlign: "left" }}
                label={"Grant Status"}
                value={search.grantStatus}
                onChange={(e) => {
                  setSearch({ ...search, grantStatus: e.target.value });
                }}
                input={<OutlinedInput label="Grant Status" />}
                MenuProps={MenuProps}
              >
                <MenuItem value={"Approved"}>Approved</MenuItem>
                <MenuItem value={"Rejected"}>Rejected</MenuItem>
                <MenuItem value={"No Preference"}>No Preference</MenuItem>
              </Select>
            </FormControl>
          </Stack>

          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid #9e1f63",
              margin: "10px 0",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                backgroundColor: "white",
                padding: "0 10px",
                color: "#9e1f63",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              CLASSIFICATIONS / CERTIFICATIONS / BUSINESS CHANNELS
            </Typography>
          </Box>

          <Stack
            sx={{ width: "100%" }}
            direction={{ xs: "column", sm: "row" }}
            spacing={2}
            justifyContent={"space-between"}
          >
            <FormControl style={{ width: "32%" }}>
              <InputLabel>Classifications</InputLabel>
              <Select
                label={"Classifications"}
                multiple
                value={search.classification}
                input={<OutlinedInput label="Classifications" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {classifications.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, search.classification, theme)}
                    onClick={(e) => handleCCBClick(e, "classification")}
                  >
                    <Checkbox
                      value={name}
                      checked={search.classification.indexOf(name) > -1}
                    />
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: "32%" }}>
              <InputLabel>Certifications</InputLabel>
              <Select
                label={"Certifications"}
                multiple
                value={search.certification}
                input={<OutlinedInput label="Certifications" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {certifications.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, search.certification, theme)}
                    onClick={(e) => handleCCBClick(e, "certification")}
                  >
                    <Checkbox
                      value={name}
                      checked={search.certification.indexOf(name) > -1}
                    />
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl style={{ width: "32%" }}>
              <InputLabel>Business Channels</InputLabel>
              <Select
                label={"Business Channels"}
                multiple
                value={search.businessChannels}
                input={<OutlinedInput label="Business Channels" />}
                renderValue={(selected) => (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {selected.map((value) => (
                      <Chip key={value} label={value} />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {businessChannels.map((name) => (
                  <MenuItem
                    key={name}
                    value={name}
                    style={getStyles(name, search.businessChannels, theme)}
                    onClick={(e) => handleCCBClick(e, "businessChannels")}
                  >
                    <Checkbox
                      value={name}
                      checked={search.businessChannels.indexOf(name) > -1}
                    />
                    {name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>

          <Box
            sx={{
              width: "100%",
              borderBottom: "1px solid #9e1f63",
              margin: "10px 0",
              position: "relative",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                backgroundColor: "white",
                padding: "0 10px",
                color: "#9e1f63",
                position: "absolute",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            >
              LOCATION
            </Typography>
          </Box>

          <Autocomplete
            multiple
            id="tags-standard"
            options={zipcodes}
            onChange={(event, value) => {
              let mappedResult: Array<string> = value.map((item) => {
                return item.split(" ")[0];
              });
              setSearch({ ...search, zipcode: mappedResult });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="Zip Code"
                placeholder="Zip Code"
                value={search["zipcode"]}
                onChange={(e) => {}}
              />
            )}
            style={{
              width: "100%",
              margin: "auto",
            }}
          />
          <Autocomplete
            value={search["city"].filter(function (item) {
              return item !== "";
            })}
            multiple
            id="tags-standard"
            options={cities}
            onChange={(event, value) => {
              let mappedResult: Array<string> = value.map((item) => {
                return item;
              });
              setSearch({ ...search, city: mappedResult });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                label="City"
                placeholder="City"
                value={search["city"]}
                onChange={(e) => {}}
              />
            )}
            style={{
              width: "100%",
              margin: "auto",
            }}
          />

          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            sx={{ width: "50%", margin: "auto" }}
          >
            <Button
              style={{ backgroundColor: "#9e1f63", color: "white" }}
              variant="contained"
              onClick={handleSubmit}
            >
              Search
            </Button>
            <Button
              style={{ backgroundColor: "#9e1f63", color: "white" }}
              variant="contained"
              onClick={() => setSearch(searchInit)}
            >
              Clear Filter
            </Button>
          </Stack>
        </Stack>
      )}
      {firmData["firms"].length > 0 ? (
        <Box sx={{ marginTop: "30px" }}>
          <Stack
            sx={{ width: "50%", margin: "auto" }}
            direction={"row"}
            spacing={2}
            justifyContent={"space-between"}
          >
            <Button
              style={{
                backgroundColor: "#9e1f63",
                color: "white",
                marginBottom: "10px",
              }}
              variant="contained"
              onClick={handleUserSelect}
            >
              Create Visit Plan
            </Button>
            {!showFilter ? (
              <Button
                style={{
                  backgroundColor: "#9e1f63",
                  color: "white",
                  marginBottom: "10px",
                }}
                variant="contained"
                onClick={() => setShowFilter(true)}
              >
                Show Filter
              </Button>
            ) : (
              <Button
                style={{
                  backgroundColor: "#9e1f63",
                  color: "white",
                  marginBottom: "10px",
                }}
                variant="contained"
                onClick={() => setShowFilter(false)}
              >
                Hide Filter
              </Button>
            )}
          </Stack>

          <div>
            <input
              type="checkbox"
              id="showOnlyAvailableFirms"
              checked={showOnlyAvailableFirms}
              onChange={() =>
                setShowOnlyAvailableFirms(!showOnlyAvailableFirms)
              }
            />
            <label htmlFor="showOnlyAvailableFirms" className="ml-1">
              Show only Available Firms
            </label>
          </div>
          <Typography
            sx={{
              textAlign: "right",
              textDecoration: "underline",
              cursor: "pointer",
              color: "#006d86",
            }}
            onClick={() => {
              exportFirms(displayFirmList);
            }}
          >
            Export Firm Result
          </Typography>
          <FirmListing
            handleFirmSelect={handleFirmSelect}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}
            firmData={{ firms: displayFirmList }}
            selectable={true}
          />
        </Box>
      ) : (
        <>{apiCalled && <h2>No Record Found</h2>}</>
      )}

      <ThriveModal
        height={750}
        open={newFirmOpen}
        contentComponent={
          <EditFirm
            firm={editedFirm}
            editedFirm={editedFirm}
            setEditedFirm={setEditedFirm}
            editedGrant={editedGrant}
            setEditedGrant={setEditedGrant}
            user={user}
            dataFlag={dataFlag}
            setDataFlag={setDataFlag}
            setOpen={setNewFirmOpen}
            newFirm={true}
            openCasePage={true}
          ></EditFirm>
        }
      ></ThriveModal>
      <ThriveModal
        open={userSelectOpen}
        contentComponent={
          <ThriveUserSelection
            selectedUser={selectedUser}
            setSelectedUser={setSelectedUser}
            currentUser={user}
            handleNext={handleVisitPlan}
            setOpen={setUserSelectOpen}
          ></ThriveUserSelection>
        }
      ></ThriveModal>
    </Box>
  );
};

export default ExploreFirm;
